import * as React from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { buildEmptyValue, MenuItemEntry } from '@controls/utils'

export const useBuildMenuOptionsWithValuesAsLabels = <TData extends ReadonlyArray<string>>(
  queryResult: UseQueryResult<TData>,
  emptyValueLabel?: string
): Omit<UseQueryResult<ReadonlyArray<MenuItemEntry>>, 'refetch'> =>
  React.useMemo(
    () => ({
      ...queryResult,
      data: [
        ...(emptyValueLabel ? [buildEmptyValue(emptyValueLabel)] : []),
        ...(queryResult.data?.map((item) => ({ value: item, label: item })) ?? [])
      ]
    }),
    [queryResult, emptyValueLabel]
  )
