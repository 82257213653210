import * as z from 'zod'
import { VehicleMakeSchema, VehicleModelSchema } from '@api/endpoints'
import { TransmissionTypeTranslationIdSchema, WheelDriveTranslationIdSchema } from '@api/endpoints/translations'
import { BodyTypeEnumSchema, FuelTypeEnumSchema } from '@api/endpoints/vehicles'
import { AllValueSchema } from '@controls/types'
import { EmptyStringValueSchema } from '@forms/types'

export const FormNumericValuesRangeSchema = z.object({
  from: z.number().or(EmptyStringValueSchema).optional(),
  to: z.number().or(EmptyStringValueSchema).optional()
})
export type FormNumericValuesRange = z.infer<typeof FormNumericValuesRangeSchema>

export const CatalogFiltrationFormSchema = z.object({
  variants: BodyTypeEnumSchema.array(),
  make: VehicleMakeSchema.or(AllValueSchema),
  model: VehicleModelSchema.or(AllValueSchema),
  fuels: FuelTypeEnumSchema.array(),
  transmissions: TransmissionTypeTranslationIdSchema.array(),
  wheelDrives: WheelDriveTranslationIdSchema.array(),
  price: FormNumericValuesRangeSchema,
  powerInKw: FormNumericValuesRangeSchema,
  year: FormNumericValuesRangeSchema,
  mileage: FormNumericValuesRangeSchema,
  engineDisplacementRange: FormNumericValuesRangeSchema
  // warrantyTypeId: WarrantyTypeEnumSchema.or(AllValueSchema)
})
export type CatalogFiltrationForm = z.infer<typeof CatalogFiltrationFormSchema>
