import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { isEmptyString } from '@digital-magic/ts-common-utils'
import { VehicleMake, VehicleModel } from '@api/endpoints'
import { getVehicleModelsByMake } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetCarModelsByMake } from './queryKeys'

export const useQueryGetVehicleModelsByMake = (
  make: VehicleMake | undefined
): UseQueryResult<ReadonlyArray<VehicleModel>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeyGetCarModelsByMake(make ?? ''),
    queryFn: () => getVehicleModelsByMake(make ?? ''),
    enabled: !isEmptyString(make)
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
