import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { VehicleMake } from '@api/endpoints'
import { getVehicleMakes } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetCarMakes } from './queryKeys'

export const useQueryGetVehicleMakes = (): UseQueryResult<ReadonlyArray<VehicleMake>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetCarMakes,
    queryFn: getVehicleMakes
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
