import { VehicleMake } from '@api/endpoints'
import { useQueryGetVehicleModelsByMake } from '@hooks/ReactQuery/vehicles'
import { useBuildMenuOptionsWithValuesAsLabels } from '.'

export const useVehicleModelMenuOptionsByMake = (
  make: VehicleMake | undefined,
  emptyValueLabel?: string
): ReturnType<typeof useBuildMenuOptionsWithValuesAsLabels> => {
  const getVehicleModels = useQueryGetVehicleModelsByMake(make)
  return useBuildMenuOptionsWithValuesAsLabels(getVehicleModels, emptyValueLabel)
}
